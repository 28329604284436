(function (global){
/**
 * Gametopic: Some dirty mocking of user interactions
 */
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null)

var navigation = require('./navigation')

var music = require('./music')
var wear = require('./wear')
var funeral = require('./funeral')
var inheritance = require('./inheritance')
var deathNotice = require('./deathNotice')
var fears = require('./fears')
var lastWill = require('./lastWill')

$(function() {

	if (sessionStorage.getItem('game.startedAt') === null)
	{
		sessionStorage.setItem('game.startedAt', new Date().getTime());
	}

	// Save data a small while after last change
	var delay = 150, timer

	$(document).on('change', '[data-ajax] input', function(e) {

		var $form = $(this).parents('form')

		if ($(e.target).attr('id') === 'frm-inheritanceForm-note')
		{
			$(document).trigger('userinput.inheritance.ms')
		}
		else if ($(e.target).attr('id') === 'frm-fearsForm-note')
		{
			saveFears()
		}

		clearTimeout(timer)
		timer = setTimeout(function() {
			$form.netteAjax(e)
		}, delay)
	})

	if (localStorage.getItem('first-form-cancelled'))
	{
		$('.gametopic-footer').removeClass('hidden')
	}

	$(document).on('click', '[data-cancel-first-form]', function() {
		localStorage.setItem('first-form-cancelled', 1)
		$('.gametopic-footer').removeClass('hidden')
		ga('send', 'event', 'game', 'firstForm', 'skipped');
	})
	var restartCancel = function() {
		localStorage.clear()
		return true
	}
	$(document).on('click', '[data-reset-link]', restartCancel)
	$(document).on('click', '[data-logout-link]', restartCancel)

	// reset modal
	$(document).on('click', '[data-reset-modal]', function() {
		$('#modal-resetPromp').modal()
	})

})

}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})