(function (global){
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null)

// Save coffin/urn
$(document).on('click', '.gametopic-funeral-form-choice', function() {
	var $input = $('#frm-funeralForm-coffin')
	var isCoffin = $(this).parents('.gametopic-funeral-coffin').length
	$input.val(isCoffin ? 'coffin' : 'urn')
	if (isCoffin) {
		$('.gametopic-funeral-urn a').removeClass('is-active')
		$('.gametopic-funeral-coffin a').addClass('is-active')
		$('.gametopic-funeral-fields').removeClass('is-urn').addClass('is-coffin')
	} else {
		$('.gametopic-funeral-urn a').addClass('is-active')
		$('.gametopic-funeral-coffin a').removeClass('is-active')
		$('.gametopic-funeral-fields').removeClass('is-coffin').addClass('is-urn')
	}
	$input.trigger('change')

	// animate
	$('html, body').animate({
		scrollTop: $('#funeral-description').offset().top
	}, 300, function(){

		});

	return false;
})

}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})