(function (global){
//import naja from 'naja';
//naja.initialize();

//netteForms = require('../../vendor/nette/forms/src/assets/netteForms.js');
//netteForms.initOnLoad();

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null)

require('../libs/jquery.transit/jquery.transit')
require('../libs/bootstrap-stylus/js/alert')
require('../libs/bootstrap-stylus/js/collapse')
require('../libs/bootstrap-stylus/js/transition')
require('../libs/bootstrap-stylus/js/scrollspy')
require('../libs/bootstrap-stylus/js/modal')
require('../libs/bootstrap-stylus/js/tooltip')
//require('../libs/bootstrap-stylus/js/popover')


require('./gametopic')
require('./mylist')
require('./profile')
require('./texts')

// OnDomReady
$(function() {
	
	// Jquery Custom selectors (inspired jquery.validate)
	$.extend( $.expr[ ":" ], {
		// http://jqueryvalidation.org/blank-selector/
		blank: function( a ) {
			return !$.trim( "" + $( a ).val() );
		},
		// http://jqueryvalidation.org/filled-selector/
		filled: function( a ) {
			return !!$.trim( "" + $( a ).val() );
		},
		// http://jqueryvalidation.org/unchecked-selector/
		unchecked: function( a ) {
			return !$( a ).prop( "checked" );
		}
	});

	// Map contenteditables to hidden form inputs
	// - load values
	$('.input-text').each(function() {
		//input - hidden field
		var $input = $('#' + $(this).data('map-to'));
		$(this).html($input.val());
	});
	// - save values
	$(document).on('blur keyup paste input', '.input-text', function() {
		var el = $(this)
		//input - hidden field
		var $input = $('#' + el.data('map-to'))
		// Preserve whitespace but discard html
		var ce = $('<pre />').html(el.html())
		ce.find('div').replaceWith(function() { return '\n' + this.innerHTML })
		ce.find('p').replaceWith(function() { return this.innerHTML + '<br>' })
		ce.find('br').replaceWith('\n')
		// Save
		$input.val(ce.text())
		//console.log(ce.text())
		$input.trigger('change')
	})

	// Nette forms ajax handler
	$.nette.init(function (ajaxHandler) {
		$('form[data-ajax]').on('submit', ajaxHandler)
	})

	//init clipboard
	var clipboard = new ClipboardJS('.btn');

	clipboard.on('success', function (e) {
	  console.info(e.action);
	  console.info('Text:', e.text);
	  //console.info('Trigger:', e.trigger);
	  //alert($(e.target).attr('data-clipboard-confirm'))
	  e.clearSelection();
	});

	// Select all link input content
	$('.mylist-share-link').on('click', function() {
		this.select()
	})

	// Initialize popovers - leave open on mouseover
	
	/*
	$('.popover-trigger').popover({
	    html: true,
	    trigger: 'manual',
	    container: $(this).attr('id'),
	    placement: 'bottom',
	    content: function () {
	        $return = '<div class="hover-hovercard"></div>';
	    }
	}).on("mouseenter", function () {
	    var _this = this;
	    $(this).popover("show");
	    $(this).siblings(".popover").on("mouseleave", function () {
	        $(_this).popover('hide');
	    });
	}).on("mouseleave", function () {
	    var _this = this;
	    setTimeout(function () {
	        if (!$(".popover:hover").length) {
	            $(_this).popover("hide")
	        }
	    }, 100);
	});
	*/

	// Initialize tooltips
	$('.tooltip-trigger').tooltip();


	// force opening modal after submit with errors
	$('[data-force-show]').modal();


	// progressive inputs
	$('[data-progressive]').each(function() {
		var $name = $('#' + $(this).data('progressive'));
		var $email = $(this);
		if (!$name.val().trim()) {
			$email.hide();
		}
		$name.on('change paste keyup', function() {
			if ($(this).val().trim()) {
				$email.slideDown();
			} else {
				$email.slideUp();
			}
		});
	})

	// Paste without formatting in contentetidable
	$(document).on('paste', '[contenteditable]', function(e) {
		e.preventDefault()
		var html = (e.originalEvent || e).clipboardData.getData('Text')
		// pasteHtmlAtCaret function
		var sel, range;
		if (window.getSelection) {
			// IE9 and non-IE
			sel = window.getSelection();
			if (sel.getRangeAt && sel.rangeCount) {
				range = sel.getRangeAt(0);
				range.deleteContents();

				// Range.createContextualFragment() would be useful here but is
				// only relatively recently standardized and is not supported in
				// some browsers (IE9, for one)
				var el = document.createElement("div");
				el.innerHTML = html;
				var frag = document.createDocumentFragment(), node, lastNode;
				while ( (node = el.firstChild) ) {
					lastNode = frag.appendChild(node);
				}
				var firstNode = frag.firstChild;
				range.insertNode(frag);

				// Preserve the selection
				if (lastNode) {
					range = range.cloneRange();
					range.setStartAfter(lastNode);
					range.collapse(true);
					sel.removeAllRanges();
					sel.addRange(range);
				}
			}
		} else if ( (sel = document.selection) && sel.type != "Control") {
			// IE < 9
			var originalRange = sel.createRange();
			originalRange.collapse(true);
			sel.createRange().pasteHTML(html);
		}
	})

	// Style checkboxes and radios
	// not(.c-tgl) - vyjimka pro cookie consent banner
	$('input:not(.c-tgl)').iCheck({
		checkboxClass: 'icheckbox_minimal',
		radioClass: 'iradio_minimal'
	});
	$('input').on('ifChanged', function(event){
		$(this).trigger('change');
	});


	$.slidebars();

	$('.lazyYT').lazyYT(); 


}) // onDomReady end

}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})