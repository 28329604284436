(function (global){
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null)

// @TODO Refactor this

function saveInheritance() {
	var data = []

	$('.gametopic-inheritance-dropzone').each(function() {
		var name = $(this).find('[data-name]').val()
		var email = $(this).find('[data-email]').val()
		var items = []
		$(this).find('.gametopic-inheritance-itm span').each(function() {
			items.push($(this).text())
		})
		data.push({
			name: name,
			email: email,
			items: items
		})
	})
	$('#frm-inheritanceForm-data').val(JSON.stringify(data)).trigger('change')

}

$(document).on('rendered.inheritance.ms', saveInheritance)
$(document).on('userinput.inheritance.ms', saveInheritance)

}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})