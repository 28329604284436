(function (global){
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null)

var saveWear = function() {
	$('#frm-wearForm-shoes').val($('.gametopic-wear-boots').attr('data-current'));
	$('#frm-wearForm-pants').val($('.gametopic-wear-legs').attr('data-current'));
	$('#frm-wearForm-shirt').val($('.gametopic-wear-body').attr('data-current'));
	$('#frm-wearForm-hat').val($('.gametopic-wear-head').attr('data-current'))
		.trigger('change'); // submit all
}

$(document).on('userinput.wear.ms', saveWear)

}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})