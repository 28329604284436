(function (global){
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null)
var rrssb = require('../../libs/RRSSB/js/rrssb.min')
var parseQuery = require('../../libs/jquery-plugin-query-parser/jquery-queryParser.min')

$(function() {

	var gameStartedAt = sessionStorage.getItem('game.startedAt');
	var gameDuration = (new Date().getTime() - gameStartedAt) / 1000; // seconds
	if (gameStartedAt !== null && gameDuration > 60 && gameDuration < 5 * 3600)
	{
		ga('send', 'event', 'game', 'complete', 'duration', gameDuration);
		sessionStorage.removeItem('game.startedAt');
	}

	// save privacy checkboxes
	$(document).on('change', '[data-privacy]', function() {
		$.ajax({
			url: '/js/toggle-privacy',
			data: {
				'property': $(this).data('privacy'),
				'private': $(this).prop('checked') ? 1 : 0
			}
		})
	})

	$(document).on('click', '[data-share-hook]', function() {
		var event = $(this).data('share-hook');
		console.log('shared', event);
		ga('send', 'event', 'game', 'share', event);
	})


	// BOOTSTRAP 3.0 - Open YouTube Video Dynamicaly in Modal Window
	// Modal Window for dynamically opening videos
	$(document).on('click', 'a[href^="http://www.youtube.com"], a[href^="https://www.youtube.com"], a[href^="http://www.youtube.com"]', function(e){
	  	// Store the query string variables and values
		// Uses "jQuery Query Parser" plugin, to allow for various URL formats (could have extra parameters)
		var queryString = $(this).attr('href').slice( $(this).attr('href').indexOf('?') + 1);
		var queryVars = $.parseQuery( queryString );


		// if GET variable "v" exists. This is the Youtube Video ID
		if ( 'v' in queryVars )
		{
			// Prevent opening of external page
			e.preventDefault();

			var iFrameCode = '<iframe width="100%" height="100%" scrolling="no" allowtransparency="true" allowfullscreen="true" src="https://www.youtube.com/embed/'+  queryVars['v'] +'?rel=0&wmode=transparent&showinfo=0" frameborder="0"></iframe>';

			// Replace Modal HTML with iFrame Embed
			$('#mediaModal .modal-body').html(iFrameCode);
			// Set new width of modal window, based on dynamic video content
			$('#mediaModal').on('show.bs.modal', function () {
				// Add video width to left and right padding, to get new width of modal window
				var modalBody = $(this).find('.modal-body');
				var modalDialog = $(this).find('.modal-dialog');
				var modalSpaces = parseInt(modalBody.css("padding-left")) + parseInt(modalBody.css("padding-right"));
				modalSpaces += parseInt(modalDialog.css("padding-left")) + parseInt(modalDialog.css("padding-right"));
				modalSpaces += 'px';
				// Set width of modal (Bootstrap 3.0)
			    $(this)
			    	.find('.modal-dialog')
			    	.css({
				    	width: 'calc(100vw - ' + modalSpaces + ')',
				    	height: '60vh'
				    })
			    	.find('.modal-content, .modal-body')
			    	.css('height', '100%');

			    $('html,body').scrollTop(30)
			});

			// Open Modal
			$('#mediaModal').modal();
		}
	});

	// Clear modal contents on close.
	// There was mention of videos that kept playing in the background.
	$('#mediaModal').on('hidden.bs.modal', function () {
		$('#mediaModal .modal-body').html('');
	});

})

}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})