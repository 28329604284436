(function (global){
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null)

$(document).scroll(function() {
	    var offset = 220;
		var duration = 500;

	    var y = $(this).scrollTop();
	    if (y > offset) {
	    	if (!$('.sharebuttons').hasClass('show')) {
	    		$('.sharebuttons').addClass('show');	
	    	}
	        
	    } else {
	        $('.sharebuttons').removeClass('show');
	    }

    
});


$(function() {

	// Chained animation for elements with .come 
	$(".come").delay(500).toArray().map(function(e){
	    return function(){
	        return $(e).animate({top: '10px', opacity: 1}, {duration: 400}).promise()
	    };
	}).reduce(function( cur, next ){
	    return cur.then(next);
	}, $().promise());

	$('.sharebuttons a.popup').click(function(event){
  		event.preventDefault();
    	window.open($(this).attr("href"), "popupWindow", "width=500,height=400,scrollbars=yes");
	});
	
    
    
    

});



}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})