(function (global){
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null)


/**
 * onNavigation change event handler.
 * Vola se po akci v navigaci.
 */
$(document).on('navigation.ms', function(e, data) {
	// Prvni krok zacina activeStep=0 (help), nextStep=1
	var activeStep = $(data.prev.link)
	var nextStep = $(data.next.link)

	$('html, body').scrollTop(0)
	// Determinate if step is forward or backward and animate accordingly
	var direction = data.prev.id < data.next.id ? 1 : -1
	// Validate active gametopic form only forwards
	if(direction == 1){
		var valid = validate(activeStep)
		// Ask for topic skip if not asked before
		if(!valid && !localStorage.getItem('skip' + data.prev.link)){
			var modal = $('#modal-confirmSkip')
			modal.data('active', data.prev)
			modal.data('next', data.next)
			modal.find('.js-title').text(activeStep.find('.gametopic-title').text().replace(/[0-9]. /, ''))
			modal.modal()
			$(document).trigger('external.navigation.ms', {next: getNumericID(activeStep)})
			return
		}
		// Ask for registration
		else if(data.prev.id == 1 && !$('body').hasClass('user-registered')) {
			$('#modal-enterDetails').modal()
		}
	}
	// Slide animation
	activeStep.removeClass('is-active').addClass('not-active')
	activeStep.transition({ x: direction * -150 + '%' }, 1000, function() {
		activeStep.hide()
	})
	nextStep.removeClass('not-active').addClass('is-active')
	nextStep.css({ x: direction * 150 + '%' }).show().transition({ x: '0%' }, 1000)
	// html border color change
	$('html').removeClass('step1 step2 step3 step4 step5 step6 step7').addClass(nextStep.attr('id'))

	ga('send', 'pageview', '/moje-posledni-prani/' + nextStep.attr('id'));
})

/**
 * Skip step modal handling
 */
$(document).on('click', '[data-skip-validation]', function() {
	var modal = $(this).closest('.modal')
	var active = modal.data('active')
	var next = modal.data('next')

	ga('send', 'event', 'game', 'topic', 'skip', active.id);
	localStorage.setItem('skip' + active.link, true)
	$(document).trigger('navigation.ms', {prev: active, next: next})
	$(document).trigger('external.navigation.ms', {next: next.id})
})


/**
 * Handle post render actions:
 * Vola se pri kazdem nacteni.
 */

$(document).on('rendered.navigation.ms', function() {

	var topics = $('.gametopic')
	if(!topics.length) { return }
	
	// - jump to step according to lates valid(filled) form
	// Determinate latest filled OR skipped step OR hash in url
	var latest = false
	if(~location.hash.indexOf('step')){
		latest = $(location.hash)
		topics.each(function() {
			validate(this)
		})
	} else {
		topics.each(function() {
			if(validate(this)) { 
				latest = $(this)
			}
		})
	}
	// Jump!
	if(latest){
		$(document).trigger('external.navigation.ms', {next: getNumericID(latest)})
		topics.removeClass('is-active')
		latest.addClass('is-active')
		$('html').removeClass('step1 step2 step3 step4 step5 step6 step7').addClass(latest.attr('id'))
		$('html, body').scrollTop(0)
	}
})


$(document).on('validate.ms', function(e, data) {
	return validate(data.step)
})


/**
 * Validates forms inside step
 * @param  {String|Element|jQuery}  selector or element itself of gametopic step to validate
 * @return {Boolean} true if all fields are valid and at least one is not empty
 */
function validate(step){
	var el = $(step)
	var form = el.find('form[data-ajax]')

	// Nothing to validate
	if(form.size() == 0) return true
	
    var formId = form.attr('id')

	var valid = true;
	var filled = form.find('input[type="text"]:filled, input[type="hidden"]:not([name="_do"]):filled, textarea:filled')
	var isfilled = !!filled.length

	// @TODO Hack for custom validation
	if(formId == 'frm-wearForm'){
		// 1 is default value out of select
		var invalid = $('#frm-wearForm-shoes').val() == 1 && $('#frm-wearForm-pants').val() == 1 && $('#frm-wearForm-shirt').val() == 1 && $('#frm-wearForm-hat').val() == 1
		valid = valid && !invalid
	}
	else if(formId == 'frm-inheritanceForm'){
		var data = []
		var items = []
		try {
			var val = $('#frm-inheritanceForm-data').val()
			data = $.parseJSON(val)
		} catch(e){
			console.error('inheritanceForm: Error parsing JSON')
		}
		data.forEach(function(person) {
			if(person.items.length) items.push(person.items)
		})
		valid = valid && !!items.length
	}
	else if(formId == 'frm-fearsForm'){
		valid = valid && ($('#frm-fearsForm-fears').val() != '[]')
	}

	console.log('form id : ' + getNumericID(el) +" "+formId+".", 'valid:', valid, 'filled', filled.length)
	var validated = valid && isfilled

	$(document).trigger('isfilled.navigation.ms', {id: getNumericID(el), filled: validated})
	return validated
}

/**
 * Strips 'step' from element's id
 * @param  {jQuery} element to get id from
 * @return {Number} numeric id
 */
function getNumericID(el){
	return parseInt(el.attr('id').replace('step', ''))
}


}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})