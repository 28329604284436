(function (global){
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null)

$(function() {

	$(document).on('click', '[data-delete-acc]', function() {
		$('#modal-deleteAcc').modal()
	})

})

}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})