(function (global){
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null)

// My fears mockup
// - mobile basic view (similar to My stuff mockup)
$(document).on('click', '.gametopic-fears-add button', function(e) {
	e.preventDefault()
	var block = $(this).closest('.gametopic-fears-mine')
	var fearInput = block.find('.gametopic-fears-add input[type="text"]')
	var fear = fearInput.val()
	block.find('.list-group-item-warning').removeClass('list-group-item-warning');
	// Insert item
	block.find('.gametopic-fears-list').prepend('<li class="gametopic-fears-itm list-group-item list-group-item-warning"><button class="close pull-right">&times;</button><span>' + fear + '</span></li>')
	// Clear input
	fearInput.val('')
	saveFears()
})
$(document).on('click', '.gametopic-fears-itm .close', function(e) {
	e.preventDefault()
	$(this).closest('.gametopic-fears-itm').remove();
	saveFears()
})

var saveFears = function() {
	var fears = []
	$('.gametopic-fears-itm span').each(function() {
		fears.push($(this).text())
	})
	$('#frm-fearsForm-fears').val(JSON.stringify(fears)).trigger('change')
}

saveFears()

}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})